import * as React from "react";

import Layout from "../components/Layout";

const ImprintPage = () => {
  return (
    <Layout seo={{ title: "Imprint", noIndex: true }}>
      <div className="p-6 prose dark:prose-dark prose-sm sm:prose lg:prose mx-auto">
        <h1>Site Notice</h1>
        <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
        <p>
          oomanIT GmbH
          <br />
          Zur alten Exerzierhalle 1<br />
          40476 Düsseldorf
        </p>
        <p>
          Commercial Register: HRB 87428
          <br />
          Registration court: Amtsgericht Düsseldorf
        </p>
        <p>
          <strong>Represented by:</strong>
          <br />
          Tobias Scharikow
          <br />
          Dr. Nicolas Burkhardt
        </p>
        <h2>Contact</h2>
        <p>E-mail: info@ooman.it</p>
        <h2>VAT ID</h2>
        <p>
          Sales tax identification number according to Sect. 27 a of the Sales
          Tax Law:
          <br />
          DE326138502
        </p>
        <h2>Person responsible for editorial</h2>
        <p>
          oomanIT GmbH
          <br />
          Zur alten Exerzierhalle 1<br />
          40476 Düsseldorf
          <br />
          <br />
          Vertreten durch:
          <br />
          Tobias Scharikow
          <br />
          Dr. Nicolas Burkhardt
        </p>
        <h2>
          Dispute resolution proceedings in front of a consumer arbitration
          board
        </h2>
        <p>
          We are not willing or obliged to participate in dispute resolution
          proceedings in front of a consumer arbitration board.
        </p>
        <h3>Liability for Contents</h3>{" "}
        <p>
          As service providers, we are liable for own contents of these websites
          according to Paragraph 7, Sect. 1 German Telemedia Act (TMG). However,
          according to Paragraphs 8 to 10 German Telemedia Act (TMG), service
          providers are not obligated to permanently monitor submitted or stored
          information or to search for evidences that indicate illegal
          activities.
        </p>{" "}
        <p>
          Legal obligations to removing information or to blocking the use of
          information remain unchallenged. In this case, liability is only
          possible at the time of knowledge about a specific violation of law.
          Illegal contents will be removed immediately at the time we get
          knowledge of them.
        </p>{" "}
        <h3>Liability for Links</h3>{" "}
        <p>
          Our offer includes links to external third-party websites. We have no
          influence on the contents of those websites, therefore we cannot
          guarantee for those contents. Providers or administrators of linked
          websites are always responsible for their own contents.
        </p>{" "}
        <p>
          The linked websites had been checked for possible violations of law at
          the time of the establishment of the link. Illegal contents were not
          detected at the time of the linking. A permanent monitoring of the
          contents of linked websites cannot be imposed without reasonable
          indications that there has been a violation of law. Illegal links will
          be removed immediately at the time we get knowledge of them.
        </p>{" "}
        <h3>Copyright</h3>{" "}
        <p>
          Contents and compilations published on these websites by the providers
          are subject to German copyright laws. Reproduction, editing,
          distribution as well as the use of any kind outside the scope of the
          copyright law require a written permission of the author or
          originator. Downloads and copies of these websites are permitted for
          private use only.
          <br /> The commercial use of our contents without permission of the
          originator is prohibited.
        </p>{" "}
        <p>
          Copyright laws of third parties are respected as long as the contents
          on these websites do not originate from the provider. Contributions of
          third parties on this site are indicated as such. However, if you
          notice any violations of copyright law, please inform us. Such
          contents will be removed immediately.
        </p>
      </div>
    </Layout>
  );
};

export default ImprintPage;
